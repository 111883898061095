import React from 'react';

const Home = () => {
  return (
    <div style={styles.homeContainer}>
      <header style={styles.header}>
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/paradigm-98582.appspot.com/o/paradigmStockPhotos%2Flogos%2Ffinal-image.jpg?alt=media" 
          alt="Company Logo" 
          style={styles.logo} 
        />
        <p>Live streaming from a different perspective!</p>
      </header>
      
      <main style={styles.content}>
        <h1 style={styles.title}>Paradigm Web Coming Soon</h1>
        <p>
          Paradigm empowers event hosts to centralize live streams from their attendees, enabling effortless content repurposing for future promotional campaigns. 
          Our app bridges the gap between creators, marketers, and audiences, providing a streamlined, engaging live-streaming experience tailored for maximum impact.
        </p>

        <p>In the meantime, download our app:</p>
        <div style={styles.downloadButtons}>
          <a href="https://play.google.com/store/apps/details?id=com.travisflake.frontend"  style={styles.downloadBtn} download>Download for Android</a>
          <a style={styles.downloadBtn} onClick={() => alert('iOS app coming soon!')}>Download for iOS</a>
        </div>
      </main>

      <section style={styles.dataUsage}>
        <h2>Why We Request Your Data</h2>
        <p>
          Our app requires access to your camera and microphone for live streaming functionality 
          and your location to enhance the live experience and show event-related information. 
          Please refer to our <a href="https://paradigm-live.com/PrivacyPolicy.html">Privacy Policy</a> for more details on data usage.
        </p>
      </section>

      <footer style={styles.footer}>
        <a href="https://paradigm-live.com/PrivacyPolicy.html" style={styles.footerLink}>Privacy & Security Policy</a> |
        © 2024 Paradigm LLC All rights reserved.
      </footer>
    </div>
  );
};


const styles = {
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f8f9fa',
    color: '#333',
    textAlign: 'center',
    backgroundColor:'#CCCCCC'
  },
  header: {
    paddingTop: '20px',
  },
  logo: {
    width: '150px',
    height: 'auto',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '2.5em',
    marginBottom: '20px',
  },
  downloadButtons: {
    marginTop: '20px',
  },
  downloadBtn: {
    display: 'inline-block',
    margin: '10px',
    padding: '15px 30px',
    fontSize: '1.2em',
    color: '#fff',
    backgroundColor: '#007bff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
  downloadBtnHover: {
    backgroundColor: '#0056b3',
  },
  footer: {
    padding: '20px',
    backgroundColor: '#343a40',
    color: '#fff',
    width: '100%',
    textAlign: 'center',
  },
  footerLink: {
    color: '#ccc',
    textDecoration: 'none',
    margin: '0 10px',
  },
  footerLinkHover: {
    color: '#fff',
    textDecoration: 'underline',
  },
};

export default Home;
