import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const AccountDeleteConfirmation = () => {
  const location = useLocation();
  const [message, setMessage] = useState('Processing...');
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('token');

    const confirmDelete = async () => {
      try {
        const response = await axios.get(`http://172.30.128.1:10000/confirm-delete`, { params: { email, token } });
        setMessage(response.data.message);
      } catch (error) {
        setMessage(error.response.data.message || 'Error processing request.');
      }
    };

    confirmDelete();
  }, [location.search]);

  return (
    <div style={styles.container}>
      <div style={styles.messageBox}>
        <p>{message}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  messageBox: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
};

export default AccountDeleteConfirmation;
