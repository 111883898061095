import React, { useState } from 'react';
import axios from 'axios';

const AccountDelete = () => {
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const trimmedEmail = userEmail.trim(); 
  const handleDeleteAccount = async () => {
    setIsLoading(true);

    if (!isValidEmail(userEmail)) {
      alert('Please enter a valid email.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`https://paradigm-server.onrender.com/deleteUserAccount`, { email: trimmedEmail });
      setResponseMessage(response.data.message);
      setEmailSent(true); // Set emailSent to true when the email is sent successfully
    } catch (error) {
      console.error('Error deleting account:', error);
      setResponseMessage('Error deleting account.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {!emailSent && ( // Conditionally render the input field and button based on emailSent state
        <>
          <div style={styles.inputContainer}>
            <input
              style={styles.textInput}
              placeholder="Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>
          <div style={styles.buttonContainer}>
            <button onClick={handleDeleteAccount} style={styles.deleteButton} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Delete Account'}
            </button>
          </div>
        </>
      )}
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  inputContainer: {
    marginBottom: 20,
  },
  textInput: {
    padding: 10,
    borderRadius: 5,
    border: '1px solid #ccc',
    width: '300px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    backgroundColor: '#ff0000',
    color: 'white',
    padding: '10px 20px',
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
};

export default AccountDelete;
