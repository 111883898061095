import React from 'react';
import { Link } from 'react-router-dom';



const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxHeight: '90vh', overflowY: 'scroll' }}>
      <h1>Privacy Policy</h1>
      <p>Effective Date: 8/3/24</p>
      {/* <p>Last Updated: [Insert Date]</p> */}

      <h2>Introduction</h2>
      <p>
        Welcome to Paradigm ("we", "our", "us"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at paradigmlive2024@gmail.com.
      </p>
      <p>
        When you use our mobile application and services (the "App"), you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our App and our services.
      </p>

      <h2>Information We Collect</h2>
      <h3>Personal Information Provided by You</h3>
      <p>Personal Identifiers: Name, email address, phone number, and other contact data.</p>
      <p>Profile Information: Username, profile picture, interests, and any other information you choose to provide.</p>
      <p>Content: Text, images, and videos that you upload and or stream to / from the App.</p>

      <h3>Information Automatically Collected</h3>
      <p>Device Information: Information about your mobile device, including hardware model, operating system, and device identifiers.</p>
      <p>Usage Data: Information about how you use our App, including the features you use, the actions you take, and the time, frequency, and duration of your activities.</p>
      <p>Location Data: Information about your device's location, which can be precise or imprecise. The amount of information we collect depends on the type of settings of the device you use to access the App.</p>

      <h2>How We Use Your Information</h2>
      <p>We use personal information collected via our App for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
      <ul>
        <li>To facilitate account creation and login process.</li>
        <li>To post content.</li>
        <li>To send administrative information to you.</li>
        <li>To protect our services.</li>
        <li>To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</li>
        <li>To respond to legal requests and prevent harm.</li>
        <li>To manage user accounts.</li>
        <li>To deliver services to the user.</li>
        <li>To respond to user inquiries/offer support to users.</li>
        <li>To send you marketing and promotional communications.</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>We may process or share your data that we hold based on the following legal basis:</p>
      <ul>
        <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
        <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
        <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
        <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena.</li>
        <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
      </ul>
      <p>We do not sell personal data to third parties.</p>

      <h2>Data Retention</h2>
      <p>We will retain your personal information for an indefinite period to continuously enhance and personalize your user experience, unless you request otherwise.</p>
      
      <h2>Security of Your Information</h2>
      <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>

      <h2>Your Privacy Rights</h2>
      <p>You have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>

      <h2>Contact Us</h2>
      <p>If you have questions or comments about this policy, you may contact our Data Protection department, by email at paradigmlive2024@gmail.com</p>

      <p>To delete your account please <Link to="/AccountDelete">click here</Link></p>
    </div>
  );
};

export default PrivacyPolicy;