import React from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AccountDelete from "./pages/AccountDelete";
import AccountDeleteConfirmation from "./pages/AccountDeleteConfirmation";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} exact element={<Home />}/>
        <Route path={`${process.env.PUBLIC_URL}/privacypolicy`} exact element={<PrivacyPolicy />} />
        <Route path={`${process.env.PUBLIC_URL}/accountdelete`} exact element={<AccountDelete />} />
        <Route path={`${process.env.PUBLIC_URL}/accountdeleteconfirmation`} exact element={<AccountDeleteConfirmation />} />
      </Routes>
    </Router>
  );
}

export default App;
